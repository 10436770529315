import React from 'react';
import LogoAnim from "../components/LogoAnim";
import '../css/Home.css';
import LINKS_LIST from "../constants/LINKS_LIST";


const links = LINKS_LIST.map(({image, link, label})=>{
   return <a key={link} className="linkGroup" href={link}>
      <p>{label}</p>
   </a>
})

const Home = ({underDevelopment}) => {
   let messageElement = <h2>"Cannot reach server. For now, check out my links here:"</h2>
   if(underDevelopment){
      messageElement = <React.Fragment>
         <h2>
            Main site is currently under development, please click <a style={{
               "color":"blue"
            }} href="http://vadapega.akawah.net/backup/">here</a> for a backup version.
         </h2>
         
         <h2>
            Should the backup also be down, check out my links here:
         </h2>
      </React.Fragment>
   }
   return <div className="Home">
      <LogoAnim />
         {messageElement}
      <div className="links">
         {links}
      </div>
   </div>
}

export default Home;
