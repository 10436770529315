import React, { Component } from "react";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
        error: false,
      };
    }
    async componentDidMount() {
      try {
        const { default: component } = await importComponent();
        this.setState({ component });
      } catch (error){
        this.setState({ error: true });
      }
    }
    render() {
      const Component = this.state.component;
      return Component ? <Component {...this.props} /> : 
        this.state.error && <div>
          There was an error loading the page.
        </div>
    }
  }

  return AsyncComponent;
}