import React, { Component } from 'react';
import './App.css';
import {
	BrowserRouter as Router,
	Route,
} from 'react-router-dom';
import Navbar from './components/Navbar';
import Transitional from './components/Transitional';
import AsyncComponent from './components/AsyncComponent';
import HomeOffline from './pages/Home_Offline';
const importComponent = component => AsyncComponent(() => import(`./${component}.js`));
const routes = [
	{
		text: "Home",
		title: "Home",
		path:"/",
		Component: importComponent("pages/Home"),
	},
	{
		text: "Projects",
		title: "Projects",
		path:"/projects",
		Component: importComponent("pages/Projects"),
		onClick:()=>{window.location.hash=""},
	},
	{
		text: "Portfolio",
		title: "Portfolio",
		path:"/portfolio",
		Component: importComponent("pages/Portfolio"),
	},
	{
		text: "Commissions",
		title: "Commissions",
		path:"/commissions",
		Component: importComponent("pages/Commissions"),
	},
];
const redirectRoutes = {
	"/roms" : "/projects",
};
const validRoutes = new Set(routes.map(({path})=>path));
const navbarEnabled = true;
class App extends Component {
	constructor(props){
		super(props);
		this.state = {
			loadedExternalFiles: null,
			offlineMode: (()=>process.env.REACT_APP_OFFLINE_UNDER_DEVELOPMENT ? true : false)(),
		}
	}
	componentDidMount(){
		if(this.state.offlineMode) return;
		// Check if dummy file exists
		var img = new Image();
      img.onload = () => { 
			this.setState({loadedExternalFiles: true})
		};
      img.onerror = () => {
			this.setState({loadedExternalFiles: false})
		 };
      img.src = `${process.env.REACT_APP_FILES_DIRECTORY}/dummy.png`;
	}
	onMouseMove = event => {
			let x = event.clientX / window.innerWidth;
			let y = event.clientY / window.innerHeight;
		
			const root = document.documentElement;
			root.style.setProperty('--mouse-x', x);
			root.style.setProperty('--mouse-y', y);
	}
	loadedComponents = []
	setDocumentTitle = () => {
		for(let i = 0; i < routes.length; i++){
			if(routes[i].path === this.activePage){
				document.title = `${routes[i].title || "Not Found"} - VAdaPEGA's Website`;
			}
		}
	}
	render() {
		// If homepage is ".", then it might be the ftp version, so our directory is /backup. Otherwise this is the Surge version, so our directory is just /
		const basename = document.querySelector('base')?.getAttribute('href') === "." ? '/backup' : "/"
		return (
			<div className="App" onMouseMove={this.onMouseMove}>
				{this.state.loadedExternalFiles && !this.state.offlineMode ?
					<Router basename={basename}>
						<div className={"contained"}>
							{navbarEnabled && <Navbar routes={routes} />}
							{
								<Route
									path="/"
									render={({location: {pathname}})=>{
										if(redirectRoutes[pathname]){
											pathname = redirectRoutes[pathname];
										}
										if(!validRoutes.has(pathname)){
											pathname="/";
										}
										if(this.activePage && this.activePage !== pathname){
											this.prevPage = this.activePage;
											this.activePage = pathname;
											this.setDocumentTitle();
											clearTimeout(this.transitiontask);
											this.transitiontask = setTimeout(()=>{
												this.prevPage = null;
												this.forceUpdate();
											},300);
										}else if(!this.activePage){
											this.activePage = pathname;
											this.setDocumentTitle();
										}
										return <div>
											{
												routes.map(({Component, path: route},i)=>{
												const loaded = this.loadedComponents[i];
												const active = pathname===route;
												if(active && !loaded) this.loadedComponents[i] = true;
												return (route && (active || loaded)) 
												&& <Transitional
													key={route}
													active={active}
													transitioning={active || this.prevPage===route}
												>
													<Component 
														active={active} 
														navbarDisplacement={navbarEnabled}
													/>
												</Transitional>})
											}
										</div>
									}}
								/>
							}
						</div>
					</Router>
					: 
					this.state.offlineMode ? 
					<HomeOffline underDevelopment={true} />
					:
					(this.state.loadedExternalFiles === false && <HomeOffline />)
				}
				<footer>
					<div>
						Website made by&nbsp;<a href="https://dragonwolfleo.com/" target="_blank" rel="noopener noreferrer">Dragon Wolf Leo</a>.
					</div>
					<div>
						Any featured characters not owned by VAdaPEGA are owned by their respective creators.
					</div>
				</footer>
			</div>
		);
	}
}

export default App;
