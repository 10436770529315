import React, { createRef } from 'react';
import '../css/LogoAnim.css';

class LogoAnim extends React.Component{
   constructor(props){
      super(props);
      this.canvas = createRef();
      this.animState = {};
      this.state = {
         fadeOut: false,
      }
   }
   progressAnim = () => {
      const {drawFrame, currentFrame, totalFrames} = this.animState;
      drawFrame(currentFrame);
      if(currentFrame >= totalFrames-1){
         clearInterval(this.animState.animTask);
         if(this.props.onFinish){
            setTimeout(()=>{
               this.setState({fadeOut:true})
            },800);
            setTimeout(()=>{
               this.props.onFinish();
            },1500);
         }
      } else {
         this.animState.currentFrame++;
      }
   }
   componentDidMount(){
      const image = new Image();
      setTimeout(()=>{
         image.src = './logoanim.png';
      },300);
      image.onload = () => {
         const width = 212;
         const height = 114;
         const canvas = this.canvas.current;
         canvas.width = width;
         canvas.height = height;
         const drawFrame = frameNum => {
            const ctx = this.canvas.current.getContext("2d");
            ctx.clearRect(0,0,width,height);
		      ctx.drawImage(image,0,height*frameNum,width,height,0,0,width,height);
         }
         this.animState = {
            width,
            height,
            image,
            drawFrame,
            currentFrame: 0,
            totalFrames: image.height/height,
         }
         this.animState.animTask = setInterval(this.progressAnim, 17);
      }
   }
   render(){ 
      return <div className="LogoAnim" style={{opacity:this.state.fadeOut ? 0: 1}}>
         <canvas ref={this.canvas} />
      </div>
   }
}

export default LogoAnim;