import React from "react";

const Transitional = ({
	className, 
	children, 
	transitioning,
	fadeClass = "fade",
	active=true,
	onDisplay = "",
}) => {
	const fade = transitioning ? fadeClass + (active?"in":"out") : "";
	const display = active || transitioning ? onDisplay : "displaynone";
	return <div 
		className={`absoluteleftright ${className || ""} ${fade} ${display}`}
	>
		{children}
	</div>
	
}

export default Transitional;