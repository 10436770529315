import React from "react";
import {Link} from "react-router-dom";
import "../css/Navbar.css";
const scrollToTop = () => {
	document.documentElement.scrollTop = 0;
}

class Navbar extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			menuOpen: false,
		}
	}
	onMenuButtonClick = () => {
		// Toggle menu open when menu toggle is pressed
		this.setState(prevState=>{
			return {menuOpen: !prevState.menuOpen}
		});
	}
	onLinkClick = () => {
		// Close menu when a link is clicked
		if(this.state.menuOpen){
			this.setState({menuOpen: false});
		}
	}
	render(){
		const {routes} = this.props;
		const {menuOpen} = this.state;
		return routes ? <div>
			<nav className="Navbar">
				{/* Menu Button */}
				<div className="navRow menuToggle"> 
					<div className="navLink" onClick={this.onMenuButtonClick}> Menu </div> 
				</div>
				{/* Nav list */}
				<ul className={`menu ${menuOpen ? "show-menu" : ""}`}>
					{routes.map((route,i)=><li key={i} className="navRow">
						{route.href ?
						<a className="navLink" 
							href={route.href}
							target="_blank"
							rel="noopener noreferrer"
						>{route.text}</a>
						:
						<Link 
							className="navLink" 
							to={route.path||"/"} 
							onClick={(...event)=>{
								route.onClick && route.onClick(...event);
								this.onLinkClick();
								scrollToTop();
							}}>
								{route.text}
						</Link>
						}
					</li>)}
				</ul>
			</nav>
		</div> : null;
	}
}

export default Navbar;