const LINKS_LIST = [
   {
      image: "https://backup.vadapega.art/motorroach/aboutt/logotwitter.png",
      link: "https://twitter.com/VAdaPEGA",
      label: "Twitter",
   },
   {
      image: "https://backup.vadapega.art/motorroach/aboutt/logotumblr.png",
      link: "https://vadapega.tumblr.com",
      label: "Tumblr",
   },
   {
      image: "https://backup.vadapega.art/images/icons/logopatreon.png",
      link: "https://patreon.com/VAdaPEGA",
      label: "Patreon",
   },
   {
      image: "https://backup.vadapega.art/images/icons/logonewgrounds.png",
      link: "https://vadapega.newgrounds.com/",
      label: "Newgrounds",
   },
   {
      image: "https://backup.vadapega.art/images/icons/logokofi.png",
      link: "https://ko-fi.com/vadapega",
      label: "Ko-fi",
   },

];

export default LINKS_LIST;